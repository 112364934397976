// 'use-stricts'

const BaseUrl = process.env.REACT_APP_SITE_URL




export const imgUrl = ( url ) => {
    return `${BaseUrl}/${url}`;
}

export const checkEmailValid = (email) => {
    try {
        const regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (email.match(regex)) {
            return true;
        }
    } catch(error) {
        return false;
    }
    
    return false;
}

export const checkURL = (params) => {
    var str = window.location.href;
    var lastSlash = str.lastIndexOf("/");
    // alert(str.substring(lastSlash+1));
    if (str.substring(lastSlash+1) === params) {
        return true;
    }else {
        return false;
    }
}

export const bytesToMegaBytes = (bytes) => { 
    return (bytes / (1024*1024)).toFixed(3); 
}

export const setCookie = (name, value, hours = 1) => {
    let expires = "";
    try {
        value = JSON.stringify(value)
    }
    catch(error) {}

    if (hours) {
        let date = new Date();
        date.setTime(date.getTime() + (hours*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

export const deleteCookie = (name) => {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie = name +'=; Path=/dashboard; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export const getCookie = (name) => {
    let nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for(let i=0;i < ca.length;i++) {
        let c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) {
            try {
                let a = c.substring(nameEQ.length, c.length)
                if (a && a !== "undefined") {
                    let decoded = JSON.parse(c.substring(nameEQ.length, c.length))
                    if (decoded && decoded !== "undefined") {
                        return decoded
                    }
                }
            }
            catch(error) {
                return c.substring(nameEQ.length, c.length)
            }
        }
    }
    return null;
}

export const formatNumber = (nStr) => {
    nStr += ''; 
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
     x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}

export const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


export const checkPhoneValid = (number) => {
    // var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    let phoneno = /^\+?[0-9]{3}-?[0-9]{6,12}$/;
    if (number.match(phoneno)) {
        return true;
    }
    else {
        //   alert("message");
        return false;
    }
}