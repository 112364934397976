import React, { useRef, useState, useEffect } from "react";
import Header from "../../layouts/Header";
import Particles from "react-particles-js";
import "font-awesome/css/font-awesome.min.css";
import { useList } from "react-firebase-hooks/database";
import TutorialDataService from "../../services/TutorialService";

function Info() {
  document.body.classList.add("infoWrapper");
  /* use react-firebase-hooks */
  const [tutorials, loading, error] = useList(TutorialDataService.getAll());
  return (
    <>
      <Particles
        params={{
          particles: {
            number: {
              value: 30,
            },
            size: {
              value: 3,
            },
          },
          interactivity: {
            events: {
              onhover: {
                enable: true,
                mode: "repulse",
              },
            },
          },
        }}
      />
      <div className="contentWrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="text-center mb-5">
                <img
                  className="rounded-circle img-fluid mb-3 w-50"
                  alt="Image Pham Quang Son"
                  src="/static/img/info/avatar.jpg"
                  aria-hidden
                />
                <h1>Phạm Quang Sơn</h1>
                <p className="title">Front-end developer</p>
              </div>
              <div className="basic-info mb-5">
                <h4 className="son-title">
                  <span className="son-txt">CONTACT INFOMATION</span>
                </h4>
                <ul className="son-list list-unstyled">
                  <li>
                    <i className="fa fa-user"></i>
                    Male
                  </li>
                  <li>
                    <i className="fa fa-phone"></i>
                    0369070077
                  </li>
                  <li>
                    <i className="fa fa-envelope"></i>
                    phamquangson4493@gmail.com
                  </li>
                  <li>
                    <i className="fa fa-map-marker"></i>
                    Ho Chi Minh
                  </li>
                </ul>
              </div>
              <div className="basic-info mb-5">
                <h4 className="son-title">
                  <span className="son-txt">ABOUT ME</span>
                </h4>
                <p>
                  I was born and grew up in Ba Ria Vung Tau province, the countryside is close to the sea, giving me a tan nurtured my passion for exploration. My passion for learning and self-improvement is evident.  I'm looking forward to the opportunity to contribute my skills.
                </p>
              </div>
              <div className="basic-info mb-5">
                <h4 className="son-title">
                  <span className="son-txt">SKILL</span>
                </h4>
                <ul className="list-unstyled list-inline">
                  <li className="list-inline-item w-50 mr-0">
                    - HTML (Jade, Pug)
                  </li>
                  <li className="list-inline-item w-50 mr-0">
                    - CSS (Sass, Less)
                  </li>
                  <li className="list-inline-item w-50 mr-0">- Boostrap</li>
                  <li className="list-inline-item w-50 mr-0">- Javascript</li>
                  <li className="list-inline-item w-50 mr-0">- Angular</li>
                  <li className="list-inline-item w-50 mr-0">- ReactJs</li>
                  <li className="list-inline-item w-50 mr-0">- Frontend Odoo</li>
                  <li className="list-inline-item w-50 mr-0">- GSAP</li>
                  <li className="list-inline-item w-50 mr-0">- D3.js</li>
                  <li className="list-inline-item w-50 mr-0">- SEO</li>
                  <li className="list-inline-item w-50 mr-0">
                    - Adobe Photoshop
                  </li>
                </ul>
              </div>
              <div className="basic-info">
                <h4 className="son-title">
                  <span className="son-txt">HOBBIES</span>
                </h4>
                <ul className="son-list list-unstyled">
                  <li>
                    <i className="fa fa-gamepad"></i>
                    Badminton
                  </li>
                  <li>
                    <i className="fa fa-plane"></i>
                    Travel
                  </li>
                  <li>
                    <i className="fa fa-camera"></i>
                    Photography (freelancer)
                  </li>
                  <li>
                    <i className="fa fa-laptop"></i>
                    Exploring my own personal computer
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="basic-info mb-5">
                <h4 className="son-title">
                  <span className="son-txt">EDUCATION</span>
                </h4>
                <p>
                  <strong>Ho Chi Minh City University of Science</strong>
                  <br />
                  <em>Bachelor of Science and Technology degree</em>
                </p>
                <p>
                  <strong>English</strong>
                  <br />
                  <em>Upper intermediate</em>
                </p>
              </div>
              <div className="basic-info mb-5">
                <h4 className="son-title">
                  <span className="son-txt">WORK EXPERIENCE</span>
                </h4>
                {/* call api firebase*/}
                {error && (
                  <div>
                    <strong>Error: {error}</strong>
                  </div>
                )}
                {!loading &&
                  tutorials &&
                  tutorials.map((item, index) => (
                    <div className="company-info mb-5" key={index}>
                      <h5 className="son-company-title mb-0">
                        {item.val().companyName}
                      </h5>
                      <p className="son-date">{item.val().workTime}</p>
                      <p className="son-des">
                        <strong>{item.val().jobTitle}</strong>
                        <br />
                        {item.val().description}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="waveWrapper waveAnimation">
          <div className="bgTop">
            <div
              className="wave waveTop"
              style={{
                backgroundImage: 'url("/static/img/info/wave-top.png")',
              }}
            ></div>
          </div>
          <div className="bgMiddle">
            <div
              className="wave waveMiddle"
              style={{
                backgroundImage: 'url("/static/img/info/wave-mid.png")',
              }}
            ></div>
          </div>
          <div className="bgBottom">
            <div
              className="wave waveBottom"
              style={{
                backgroundImage: 'url("/static/img/info/wave-bot.png")',
              }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Info;
