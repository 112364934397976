import React, { useState } from "react";
import TutorialDataService from "../services/TutorialService";

const AddTutorial = () => {
  const initialTutorialState = {
    companyName: "",
    workTime: "",
    jobTitle: "",
    description: "",
    published: false,
  };
  const [tutorial, setTutorial] = useState(initialTutorialState);
  const [submitted, setSubmitted] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTutorial({ ...tutorial, [name]: value });
  };

  const saveTutorial = () => {
    var data = {
      companyName: tutorial.companyName,
      workTime: tutorial.workTime,
      jobTitle: tutorial.jobTitle,
      description: tutorial.description,
      published: false,
    };

    TutorialDataService.create(data)
      .then((res) => {
        setSubmitted(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const newTutorial = () => {
    setTutorial(initialTutorialState);
    setSubmitted(false);
  };

  return (
    <div className="submit-form">
      {submitted ? (
        <div>
          <h4>You submitted successfully!</h4>
          <button className="btn btn-success" onClick={newTutorial}>
            Add
          </button>
        </div>
      ) : (
        <div>
          <div className="form-group">
            <label htmlFor="companyName">Company Name</label>
            <input
              type="text"
              className="form-control"
              id="companyName"
              required
              value={tutorial.companyName}
              onChange={handleInputChange}
              name="companyName"
            />
          </div>
          <div className="form-group">
            <label htmlFor="workTime">Work Time</label>
            <input
              type="text"
              className="form-control"
              id="workTime"
              required
              value={tutorial.workTime}
              onChange={handleInputChange}
              name="workTime"
            />
          </div>

          <div className="form-group">
            <label htmlFor="jobTitle">Job Title</label>
            <input
              type="text"
              className="form-control"
              id="jobTitle"
              required
              value={tutorial.jobTitle}
              onChange={handleInputChange}
              name="jobTitle"
            />
          </div>

          <div className="form-group">
            <label htmlFor="description">Description</label>
            <textarea
                rows="5"
              type="text"
              className="form-control"
              id="description"
              required
              value={tutorial.description}
              onChange={handleInputChange}
              name="description"
            ></textarea>
          </div>

          <button onClick={saveTutorial} className="btn btn-success">
            Submit
          </button>
        </div>
      )}
    </div>
  );
};

export default AddTutorial;
