import React, { useState, useEffect } from 'react'
import Header from '../../layouts/Header'
import TutorialsList from '../../components/TutorialsList'
import { Link, useHistory } from 'react-router-dom'
import { useAuth } from '../../services/AccountService'
import {checkEmailValid} from '../../utils/utils'

function SignIn(props) {

    const [email, setEmail] = useState('')
    const [msg, setMsg] = useState('')
    const [pwd, setPwd] = useState('')
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const history = useHistory()
    const { login } = useAuth()

    useEffect(() => {
        setError(false)
    }, [email, pwd])

    const handleLogin = async () => {
        if (email !== "" && checkEmailValid(email) && pwd !== "") {
            // handle submit 
            try {
                let res = await login(email, pwd)
                if (res) {
                    history.push('/')
                }
            } catch {
                setError(true)
                setMsg("Failed to login an account")
            }
        }
        else {
            setLoading(false)
            setError(true)
        }
    }
    return (
        <>
            <Header />
            <div className="container mt-3">
                <h2 className="text-center">Log in</h2>
                <div className="row justify-content-center">
                    <div className="col-md-4">
                        <div className="sign-up-form">
                            <div className={`form-group ${error && email === '' || error && checkEmailValid(email) === false ? 'error' : ''}`}>
                                <div>Email address</div>
                                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" />
                                {error && email === '' && <div className="text-error">Please enter your email</div>}
                                {error && email !== '' && checkEmailValid(email) === false && <div className="text-error">Your email is not correct</div>}
                            </div>
                            <div className={`form-group ${error && pwd === "" ? 'error' : ''}`}>
                                <div>Password</div>
                                <input type="password" value={pwd} onChange={(e) => setPwd(e.target.value)} className="form-control" />
                                {error && pwd === "" && <div className="text-error">Please enter your password</div>}
                            </div>
                            <div className="form-group">
                                <button onClick={() => handleLogin()} className="btn btn-success">Login</button>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>
    )
}
export default SignIn;