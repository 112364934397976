import React, {useState, useEffect} from 'react'
import Header from '../../layouts/Header'
import AddTutorial from '../../components/AddTutorial'

function AddTutorialPage (props) {
	return (
		<>
			<Header/>
			<div className="container mt-3">
				{/* <h2>React Firebase Hooks CRUD</h2> */}
                <AddTutorial />
			</div>
		</>
	)
}
export default AddTutorialPage;