
import firebase from "firebase";
import "firebase/database";
import 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyDExuuPMagdkdEp2qugXlrFFDaNm2PtRdc",
    authDomain: "react-cv-4eee5.firebaseapp.com",
    databaseURL: "https://react-cv-4eee5-default-rtdb.firebaseio.com",
    projectId: "react-cv-4eee5",
    storageBucket: "react-cv-4eee5.appspot.com",
    messagingSenderId: "586347248491",
    appId: "1:586347248491:web:feab27028698cc93a0aa2e",
    measurementId: "G-LFQJCLNJ76"
  };

const fire = firebase.initializeApp( firebaseConfig )
export const auth = fire.auth()

export default fire;