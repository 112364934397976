import React, { useState } from "react";
import TutorialDataService from "../services/TutorialService";
import { useAuth } from "../services/AccountService";

const Tutorial = (props) => {
  const initialTutorialState = {
    key: null,
    companyName: "",
    workTime: "",
    jobTitle: "",
    description: "",
    published: false,
  };
  const [currentTutorial, setCurrentTutorial] = useState(initialTutorialState);
  const [message, setMessage] = useState("");
  const { currentUser } = useAuth();

  const { tutorial } = props;
  if (currentTutorial.key !== tutorial.key) {
    setCurrentTutorial(tutorial);
    setMessage("");
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentTutorial({ ...currentTutorial, [name]: value });
  };

  const updatePublished = (status) => {
    TutorialDataService.update(currentTutorial.key, { published: status })
      .then(() => {
        setCurrentTutorial({ ...currentTutorial, published: status });
        setMessage("The status was updated successfully!");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const updateTutorial = () => {
    const data = {
      companyName: currentTutorial.companyName,
      workTime: currentTutorial.workTime,
      jobTitle: currentTutorial.jobTitle,
      description: currentTutorial.description,
    };

    TutorialDataService.update(currentTutorial.key, data)
      .then(() => {
        setMessage("The tutorial was updated successfully!");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deleteTutorial = () => {
    TutorialDataService.remove(currentTutorial.key)
      .then(() => {
        props.refreshList();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div>
      {currentTutorial ? (
        <div className="edit-form">
          <h4>Tutorial</h4>
          <form>
            <div className="form-group">
              <label htmlFor="companyName">Company Name</label>
              <input
                type="text"
                className="form-control"
                id="companyName"
                required
                value={currentTutorial.companyName}
                onChange={handleInputChange}
                name="companyName"
              />
            </div>
            <div className="form-group">
              <label htmlFor="workTime">Work Time</label>
              <input
                type="text"
                className="form-control"
                id="workTime"
                required
                value={currentTutorial.workTime}
                onChange={handleInputChange}
                name="workTime"
              />
            </div>

            <div className="form-group">
              <label htmlFor="jobTitle">Job Title</label>
              <input
                type="text"
                className="form-control"
                id="jobTitle"
                required
                value={currentTutorial.jobTitle}
                onChange={handleInputChange}
                name="jobTitle"
              />
            </div>
            <div className="form-group">
              <label htmlFor="description">Description</label>
              <textarea
                rows="5"
                type="text"
                className="form-control"
                id="description"
                required
                value={currentTutorial.description}
                onChange={handleInputChange}
                name="description"
              ></textarea>
            </div>

            <div className="form-group">
              <label>
                <strong>Status:</strong>
              </label>
              {currentTutorial.published ? "Published" : "Pending"}
            </div>
          </form>
          {currentUser != null && (
            <div>
              {currentTutorial.published ? (
                <button
                  className="badge badge-primary mr-2"
                  onClick={() => updatePublished(false)}
                >
                  UnPublish
                </button>
              ) : (
                <button
                  className="badge badge-primary mr-2"
                  onClick={() => updatePublished(true)}
                >
                  Publish
                </button>
              )}

              <button
                className="badge badge-danger mr-2"
                onClick={deleteTutorial}
              >
                Delete
              </button>

              <button
                type="submit"
                className="badge badge-success"
                onClick={updateTutorial}
              >
                Update
              </button>
              <p>{message}</p>
            </div>
          )}
        </div>
      ) : (
        <div>
          <br />
          <p>Please click on a Tutorial...</p>
        </div>
      )}
    </div>
  );
};

export default Tutorial;
