import React, { createContext, useContext, useReducer } from 'react'

const CommonStateContext = createContext('common')
const CommonDispatchContext = createContext('common')


const initialState = {
    userProfile: null,
    language: 'en',
    
}

const reducer = ( state, action ) => {
    switch (action.type) {
        case 'USER_PROFILE': 
            return { ...state, userProfile: action.payload }
        default:
            return {...state}
    }
}

const CommonStore = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    return (
        <CommonStateContext.Provider value={state}>
            <CommonDispatchContext.Provider value={dispatch}>
                {children}
            </CommonDispatchContext.Provider>
        </CommonStateContext.Provider>
    )
}
export default CommonStore

export function useCommonDispatch() {
    const context = useContext(CommonDispatchContext)
    return context
}

export function useCommonState() {
    const context = useContext(CommonStateContext)
    return context
}