import React, { useState } from "react";
import { useList } from "react-firebase-hooks/database";
import TutorialDataService from "../services/TutorialService";
import Tutorial from "./Tutorial";

const TutorialsList = () => {
  const [currentTutorial, setCurrentTutorial] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);

  /* use react-firebase-hooks */
  const [tutorials, loading, error] = useList(TutorialDataService.getAll());

  const setActiveTutorial = (tutorial, index) => {
    const {
      companyName,
      workTime,
      jobTitle,
      description,
      published,
    } = tutorial.val();
    setCurrentTutorial({
      key: tutorial.key,
      companyName,
      workTime,
      jobTitle,
      description,
      published,
    });
    setCurrentIndex(index);
  };
  return (
    <>
      <div className="list row">
        <div className="col-md-6">
          <h4>Tutorials list</h4>
          {error && (
            <div>
              <strong>Error: {error}</strong>
            </div>
          )}
          {loading && (
            <div>
              <span>{loading}</span>
            </div>
          )}
          <ul className="list-group">
            {!loading &&
              tutorials &&
              tutorials.map((item, index) => (
                <li
                  key={index}
                  className={`list-group-item ${
                    index === currentIndex ? "active" : ""
                  }`}
                  onClick={() => setActiveTutorial(item, index)}
                >
                  {item.val().companyName}
                </li>
              ))}
          </ul>
        </div>
        <div className="col-md-6">
          {currentTutorial && <Tutorial tutorial={currentTutorial} />}
          {!currentTutorial && (
            <div>
              <br />
              <p>Please click on a Tutorial...</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TutorialsList;
